import { IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import Util from "../../../../../lib/util"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import React from "react"

const UserListItem = ({key, user}) => (
  <ListItem key={key}>
    <ListItemAvatar>
      <Util.UserAvatar user={user} />
    </ListItemAvatar>
    <ListItemText
      primary={Util.getUserFullName(user)}
      secondary={user.Statement} />
    {user.LinkedIn && <ListItemSecondaryAction>
      <IconButton
        edge="end"
        aria-label="LinkedIn"
        onClick={()=>window.open(user.LinkedIn, '_blank')}
      >
        <LinkedInIcon/>
      </IconButton>
    </ListItemSecondaryAction>
    }
  </ListItem>

)

export default UserListItem;
