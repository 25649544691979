import React, {useEffect, useState, useContext} from 'react';
import {MixpanelContext} from '../../../../lib/tracker'
import {
  IconButton,
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Button, Chip, Container, CssBaseline, makeStyles, Paper, Typography, CircularProgress, ListItemIcon,

  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core"
import {
  Alert,
  AlertTitle
} from '@material-ui/lab';

import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NoteIcon from '@material-ui/icons/Note';
import VideocamIcon from '@material-ui/icons/Videocam';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import MessageIcon from '@material-ui/icons/Message';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LinkedInIcon from '@material-ui/icons/LinkedIn';


import Layout from '../../../layout'
import clsx from "clsx"
import { ElementOrLoader } from "../../../util"
import {apiPost} from "../../../../lib/api"

import {
  createEnum,
  PURPLE,
  LIGHT_BLUE,
  API_ADDRESS,
  REQUEST_ORIGIN,
  REQUEST_STATUS, APP_BAR_STATE, FRONTEND_URL, FEEDBACK_STATUS,
} from "../../../../lib/constants"

import { getAuthToken, isAuthenticated } from "../../../../lib/auth"
import axios from "axios"
import moment from "moment"
import { setAppBar } from "../system/system_slice"
import { useDispatch, useSelector} from "react-redux"
import FeedbackSurvey from "../feedback/ui/feedback"
import Util from "../../../../lib/util"
import {setLoading, setSystemData} from "../system/system_slice"
import SharedStyles from '../../../shared_styles'
import UserListItem from "./ui/user_list_item"
import { Match } from "@reach/router"

const useStyles = makeStyles((theme) =>({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    height: '100%',
    overflow: 'hidden',
    marginBottom: theme.spacing(3)
  },
  whenContainer: {
    background: "#e8f1fb",
    padding: theme.spacing(1),
    width: '100%'
  },
  noteContainer: {
    background: "#e8f1fb",
    padding: theme.spacing(1),
    width: '100%'
  },
  whenBold: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  centerElements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

  },
  internalPadding: {
    padding: theme.spacing(2)
  },
  green: {
    color: theme.palette.success.dark
  },
  red: {
    color: theme.palette.error.dark
  },
  acceptedPaper: {
    // background: theme.palette.success.light
  },
  details: {
    width: '100%',
    borderColor: theme.palette.divider
  },
  profilePicContainer: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: '75px',
    height: '75px'
  }
}));


const DECISION_STATE = createEnum(['INITIAL', 'LOADING', 'ACCEPTED', 'DECLINED'])
const CALL_LOG_LOADING_KEY = 'matchViewCallLog';
const FEEDBACK_LOADING_KEY = 'matchViewFeedback';

const MatchView = ({navigate, requestId, path, inApp, params}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);
  const {user, loading, feedback, alreadyConnected} = useSelector(state => state.system);


  const [notFound, setNotFound] = useState(false);
  const [callLog, setCallLog] = useState(null);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [localIsAuthenticated, setLocalIsAuthenticated] = useState(false);
  const [showBezel, setShowBezel] = useState(false);
  const [showCallAlert, setShowCallAlert] = useState(false);
  const [otherUser, setOtherUser] = useState({});

  const [reportIssue, setReportIssue] = useState(false);
  const [addToNetwork, setAddToNetwork] = useState(false);


  useEffect(()=>{
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'Call Details'
    }));

    if (!inApp && isAuthenticated()) {
      navigate(`/app/call/${requestId}`)
      return;
    }

    // Indicate which message referred this load.
    if (params.o) {
      const data = {'referrer-message-id':params.o};
      mixpanel.track('Text Message Referrer', data);
      apiPost('public/save-action', data).finally();
    }

    setShowBezel(!inApp);
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };



    dispatch(setLoading({key: CALL_LOG_LOADING_KEY, value: true}));
    axios.get(`${API_ADDRESS}/request/get-call-log-by-id?id=${requestId}`, config)
      .then((response) => {
        if (response.data.error) {
          return setNotFound(true);
        }

        const call_log = response.data.call_log;
        setCallLog(call_log);

        let m = moment(call_log.Request.TimeSlot);
        setDate(m.format('dddd, MMMM Do'))
        setTime(`${m.format('h:mma')} - ${m.add(30, 'minutes').format('h:mma')} ${Util.getTimezone()}`);

        setOtherUser(user.id == call_log.User1.id ? call_log.User2 : call_log.User1);
        dispatch(setLoading({key: CALL_LOG_LOADING_KEY, value: false}));
      })

    dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: true}));
    axios.get(`${API_ADDRESS}/feedback/get?id=${requestId}`, config)
      .then((response) => {
        if (response.data.error) {
          if (response.data.error_message) {
            alert(response.data.error_message);
          }
          return;
        }

        dispatch(setSystemData({key:'feedback', value:response.data.feedback}))
        dispatch(setSystemData({key:'alreadyConnected', value:response.data.connected}))
        dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: false}));
      });

    return () => {
      dispatch(setSystemData({key:'feedback', value:null}))
      dispatch(setSystemData({key:'alreadyConnected', value:null}))

    }
  }, [])

  useEffect(() => {
    mixpanel.track('Match View Page', {
      'In App': inApp
    });
  }, [mixpanel])

  const onCancel = () => {
    alert(`Cancelling a meeting isn't supported yet.`);
  }

  const isPlatformCall = () => callLog.Request.Origin == REQUEST_ORIGIN.PLATFORM
  const isAfterStartTime = () => moment(callLog.Request.TimeSlot).isBefore(moment())

  const handleReschedule = () => {
    const subject = `[Greenwoodx] Reschedule Request for your meeting ${Util.getUserFullName(callLog.User1)} <> ${Util.getUserFullName(callLog.User2)}`;
    const body = `\
Hi,

We are scheduled to meet on Greenwoodx on ${date} from ${time}. 
Greenwoodx call details: ${FRONTEND_URL}/app/call/${callLog.ExternalId}
`;
    mixpanel.track('Send Message', {
      'Surface': 'Match View',
      'Type': 'Reschedule',
      'From': user.id,
      'To': otherUser.id
    });
    openMailTo(encodeURI(subject), encodeURI(body));
  }
  const sendMessage = () => {
    const subject = `[Greenwoodx] New Message room between ${Util.getUserFullName(callLog.User1)} and ${Util.getUserFullName(callLog.User2)}`;
    const body = ``;
    mixpanel.track('Send Message', {
      'Surface': 'Match View',
      'Type': 'General',
      'From': user.id,
      'To': otherUser.id
    });
    openMailTo(encodeURI(subject), encodeURI(body));
  }

  const openMailTo = (subject, body) => {
    const grnwdxEmail = 'grnwdx@gmail.com';
    let mailTo = `mailto:${callLog.User1.Email}?to=${callLog.User2.Email}&cc=${grnwdxEmail}&subject=${subject}&body=${body}`;
    window.open(mailTo, "_blank")
  }

  const openCallAlert = () => {
    if (isPlatformCall()) {
      mixpanel.track('Seen Call Alert');
      return setShowCallAlert(true);
    } else {
      window.open(callLog.ConferenceUrl, "_blank");
    }
  }
  const closeCallAlert = () => {
    setShowCallAlert(false);
    window.open(callLog.ConferenceUrl, "_blank")
  }


  const getTitleSection = () => {
    let originLabel = callLog.origin == REQUEST_ORIGIN.WEB? 'Web' : 'Greenwoodx';
    let background = callLog.origin == REQUEST_ORIGIN.WEB? LIGHT_BLUE : PURPLE;
    return (
      <Box className={clsx([classes.centerElements])} style={{background: background}}>
        <Typography variant="subtitle2" >
          {originLabel}
        </Typography>
      </Box>
    )
  }

  const getNotFoundError = () => {
    return (
      <React.Fragment>
        <Typography variant="h5">
          Sorry, I can't find this call.
        </Typography>
        <Button
          variant='outlined'
          onClick={()=>navigate('/app')}
        >
          Go back to Greenwoodx
        </Button>
      </React.Fragment>
    )
  }

  const getUserProfilePic = (user) => {
    return (<Avatar src={user.ProfilePic}>{Util.getUserInitials(user)}</Avatar>);
    if (user.profile_pic) {
      return (<img style={{width: '100%'}} src={user.profile_pic} />);
    } else {
      return (<PersonIcon />);
    }

  }

  const getRescheduleListElement = () => {
    if (isAfterStartTime()) {
      return null;
    }
    return (
      <ListItem
        key="reschedule"
        button
        onClick={handleReschedule}>
        <ListItemIcon>
          <EventBusyIcon />
        </ListItemIcon>
        <ListItemText primary="Reschedule" secondary={`Ask ${otherUser.FirstName} if you can reschedule this call.`} />
      </ListItem>
    )
  }

  const getSendMessageListElement = () => {
    return (
      <ListItem
        key="send_message"
        button
        onClick={sendMessage}>
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText primary="Send Message" secondary={`Send ${otherUser.FirstName} a message or update.`} />
      </ListItem>
    );
  }

  const getPlatformCallElements = () => {
    // Only show these elements if the call came from Greenwoodx
    if (!isPlatformCall()) return;
    const elements = [];
    const list = [];

    if (isAfterStartTime()) {
      elements.push(
        <FeedbackSurvey
          feedback={feedback}
          alreadyConnected={alreadyConnected}
          key="feedback"
          user={otherUser}
          externalId={callLog.ExternalId}
          openReportIssue={reportIssue}
          addToNetwork={addToNetwork}
          disabled={!isAfterStartTime()}
        />
      )
    } else {
      elements.push(
        <Alert severity="info">Feedback and the options below will be enabled after the call starts.</Alert>
      );
    }

    const addToNetworkDisabled = (feedback && (feedback.SaveConnection || !feedback.HadCall)) || !isAfterStartTime();
    list.push(

      <ListItem
        key="connect"
        button
        onClick={() => {setAddToNetwork(true)}}
        disabled={addToNetworkDisabled}
      >

        <ListItemIcon>
          <GroupAddIcon />
        </ListItemIcon>
        <ListItemText
          primary={addToNetworkDisabled && (feedback && feedback.SaveConnection)?'Request Sent':`Add ${otherUser.FirstName} to your network`}
          secondary="You will be able to schedule calls directly on their calendar from your contact list. Note: They also have to add you to their network to be able to schedule time with them again." />
      </ListItem>
    )


    if (!reportIssue) {
      list.push(
        <ListItem
          key="report"
          button
          onClick={() => setReportIssue(true)}
          disabled={addToNetworkDisabled}
        >
          <ListItemIcon>
            <ErrorIcon />
          </ListItemIcon>
          <ListItemText primary="Report an Issue" secondary={`Did ${otherUser.FirstName} violate our Community Guidelines?`} />
        </ListItem>
      )
    }

    elements.push(
      <List key="list" >
        {list}
      </List>
    );
    return elements;
  }

  const getOriginSpecificCallDetails = () => {
    const list = [];
    list.push(<UserListItem key='user1' user={callLog.User1} />);
    list.push(<UserListItem key='user2' user={callLog.User2} />);

    let originChip = null;
    if (isPlatformCall()) {
      originChip = ( <Chip label={callLog.Request.Topic.Label} color="primary"/> );
    } else {
      originChip = ( <Chip label="Direct Request" color="primary" variant="outlined"/> );
    }

    list.push(
      <ListItem key="topic">
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText
          primary={originChip} />
      </ListItem>
    );

    return list;
  }

  const getPlatformCallDetailListItems = () => {
    const list = [];

    return list;
  }

  const getBody = () => {
    if (!callLog) return null;
    let note_element = null;
    if (callLog.Request.Note) {
      note_element = (
        <ListItem>
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary={callLog.Request.Note} />
        </ListItem>
      );
    }

    let conferenceListItemText = null;
    if (callLog.ConferenceUrl) {
      const disabled = moment().isBefore(moment(callLog.datetime).subtract(10, 'minutes'));
      conferenceListItemText = (
        <ListItemText
          primary={
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={openCallAlert}
            >
              Join video call
            </Button>
          }
          secondary={
            <Typography variant="body2">
              This button will be enabled 10 minutes before the scheduled time.
            </Typography>
          } />
      );
    } else {
      conferenceListItemText = (
        <ListItemText
          primary={
            <Typography variant="body2">
              We're still generating your video call link. Check back in later.
            </Typography>
          } />
      );
    }

    return (
      <React.Fragment>
        <Paper className={classes.paper} elevation={1} >
          <Grid container spacing={1} className={classes.internalPadding} >
            {showBezel &&
              <Box className={classes.centerElements}>
                <Typography variant="subtitle2" color="primary">
                  Call Details
                </Typography>
              </Box>

            }
            <Box className={classes.details} borderBottom={1}>
                <List>
                  {getOriginSpecificCallDetails()}
                  <ListItem>
                    <ListItemIcon>
                      <CalendarTodayIcon />
                    </ListItemIcon>
                    <ListItemText primary={date} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText primary={time} />
                  </ListItem>
                  {note_element}
                  <ListItem alignItems="flex-start">
                    <ListItemIcon>
                      <VideocamIcon />
                    </ListItemIcon>
                    {conferenceListItemText}
                  </ListItem>
                  {isPlatformCall() &&
                    <ListItem
                      key="call_tips"
                      button
                      onClick={() => window.open('http://bit.ly/greenwoodx_call_tips', '_blank')}
                    >
                      <Alert
                        severity='info'
                        icon={
                          <DescriptionIcon/>
                        }
                      >
                        <AlertTitle><strong>Call Tips</strong></AlertTitle>
                        Meeting someone new for the first time can be weird, here are some tips to help you out.
                      </Alert>
                    </ListItem>
                  }
                </List>
            </Box>
          </Grid>
          {/*<Button*/}
          {/*  onClick={onCancel}*/}
          {/*  fullWidth*/}
          {/*>*/}
          {/*  Cancel*/}
          {/*</Button>*/}
        </Paper>
        {getPlatformCallElements()}
        {getRescheduleListElement()}
        {getSendMessageListElement()}
      </React.Fragment>
    );
  }

  return (
    <Container maxWidth="sm">
      <ElementOrLoader loading={loading[CALL_LOG_LOADING_KEY]}>
        {notFound && getNotFoundError()}
        {!notFound && getBody()}

      </ElementOrLoader>
      <Dialog open={showCallAlert}>
        <DialogTitle>{"Tell us about your call"}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            Return to this page after your call and tell us if:
            <ul>
              <li>{otherUser.FirstName} showed up for the call</li>
              <li>You would like to add {otherUser.FirstName} to your network on Greenwoodx</li>
              <li>You need to report something that violated our Community Guidelines</li>
            </ul>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeCallAlert}
            variant="outlined"
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default MatchView;
