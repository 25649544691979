import React, { useEffect, useState, useContext } from "react"
import {MixpanelContext} from "../../../../../lib/tracker"
import { API_ADDRESS, createEnum, FEEDBACK_STATUS } from "../../../../../lib/constants"
import {
  Box,
  Typography,
  Paper,
  makeStyles, Grid, TextField,

  List,
  ListItem,
  ListItemIcon,
  ListItemText, Button, CircularProgress, CssBaseline, FormLabel,

  StepContent
} from "@material-ui/core"

import {
  ToggleButtonGroup,
  ToggleButton,

} from "@material-ui/lab"
import SharedStyles from '../../../../shared_styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx'
import axios from "axios"
import { getAuthToken } from "../../../../../lib/auth"
import { ElementOrLoader } from "../../../../util"
import Util from "../../../../../lib/util"
import {useDispatch, useSelector} from "react-redux"
import {setLoading, setSystemData} from "../../system/system_slice"

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginBottom: theme.spacing(3)
  },
  infoIcon: {
    marginTop: '-3px',
    fontSize: '14px',
    marginRight: theme.spacing(1)

  },
  container: {
    background: '#ffbe763d'
  }
}))

const ISSUE = 'isssue';
const STEPS = createEnum(['HAD_CALL', 'STAY_CONNECTED', 'ISSUE', 'NO_CALL', 'THANK_YOU', 'ISSUE_FINAL']);

const CONST_YES = 'Yes';
const CONST_NO = 'No';

const FEEDBACK_LOADING_KEY = 'matchViewFeedback';

const FeedbackSurvey = ({user, externalId, openReportIssue, addToNetwork}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();
  const mixpanel = useContext(MixpanelContext);

  const {loading, feedback, alreadyConnected} = useSelector(state => state.system);


  const [step, setStep] = useState(STEPS.HAD_CALL);

  const [issue, setIssue] = useState('');
  const [hadCall, setHadCall] = useState('');
  const [stayConnected, setStayConnected] = useState('');
  const [relevant, setRelevant] = useState('')
  const [extraInfo, setExtraInfo] = useState('');

  useEffect(() => {
    if (feedback){
      if (feedback.Status == FEEDBACK_STATUS.DONE) {
        if (!feedback.HadCall || feedback.Issue || feedback.NotRelevant) {
          setStep(STEPS.ISSUE_FINAL);
        } else {
          setStep(STEPS.THANK_YOU);
        }
      } else if (!alreadyConnected && feedback.Status == FEEDBACK_STATUS.HAD_CALL) {
        setHadCall(CONST_YES);
        setStep(STEPS.STAY_CONNECTED);
      }
    }
  }, [feedback, alreadyConnected])


  useEffect(() => {
    if(openReportIssue)   {
      setHadCall(CONST_YES);
      setRelevant(CONST_NO)
      setStep(STEPS.ISSUE)
    }
  }, [openReportIssue])

  useEffect(() => {
    if (addToNetwork) {
      if(!feedback || (feedback && feedback.Status != FEEDBACK_STATUS.DONE)) {
        setHadCall(CONST_YES);
        handleStayConnectedChange(null, CONST_YES);
      }
    }
  }, [addToNetwork])


  const handleTextField = (event) => {
    setIssue(event.target.value);
  }

  const getIssueFinal = () => {
    return (
      <React.Fragment>
        <Typography className={styles.centerText}>😔</Typography>
        <Typography variant="subtitle1" className={clsx([styles.boldText, styles.centerText])}>Sorry this was your experience</Typography>
        <Typography variabt="body1">We investigate every no-show, bad match, and further issue personally. Thank you for letting us know.</Typography>
      </React.Fragment>
    );
  }

  const getThanks = () => {
    return (
      <React.Fragment>
        <Typography className={styles.centerText}>😊</Typography>
        <Typography variant="subtitle1" className={clsx([styles.boldText, styles.centerText])}>Thank You</Typography>
      </React.Fragment>
    );

  }

  const handleHadCallChange = (event, newValue) => {
    setHadCall(newValue);
    dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: true}));
    const data = {
      id: externalId,
      had_call: newValue == CONST_YES
    };
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.post(`${API_ADDRESS}/feedback/submit`, data, config)
      .then(() => {
        mixpanel.track('Feedback Submitted', data);
        const localFeedback = Object.assign({}, feedback);
        // Artificially delay changing steps so use can notice the switch.
        setTimeout(() => {
          if (newValue == CONST_YES) {
            if (alreadyConnected) {
              setStep(STEPS.THANK_YOU);
            } else {
              setStep(STEPS.STAY_CONNECTED);
            }
            localFeedback.HadCall = true;
          } else {
            localFeedback.HadCall = false;
            setStep(STEPS.ISSUE_FINAL);
          }
          dispatch(setSystemData({key: 'feedback', value: localFeedback}));
          dispatch(setSystemData({key: 'alreadyConnected', value: true}));
          dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: false}));
        }, 1000)
      });
  }

  const handleStayConnectedChange = (event, newValue) => {
    setStayConnected(newValue);
    dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: true}));
    if (newValue == CONST_YES) {
      const data = {
        id: externalId,
        had_call: true,
        save: true
      };
      const config = {
        headers: { Authorization: `Bearer ${getAuthToken()}` }
      };
      axios.post(`${API_ADDRESS}/feedback/submit`, data, config)
        .then(() => {
          mixpanel.track('Feedback Submitted', data);
          setStep(STEPS.THANK_YOU);
          const localFeedback = Object.assign({}, feedback);
          localFeedback.SaveConnection = true;
          dispatch(setSystemData({key: 'feedback', value: localFeedback}));
          dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: false}));
        });
    } else { // CONST_NO index = 1
      // Artificially delay changing steps so use can notice the switch.
      setTimeout(() => {
        setStep(STEPS.ISSUE);
        dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: false}));
      }, 1000);
    }
  }

  const handleRelevant = (event, newValue) => {
    setRelevant(newValue);
  }

  const handleSubmit = () => {
    dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: true}));
    const data = {
      id: externalId,
      had_call: true,
      save: false,
      not_relevant: relevant == CONST_NO,
      issue: issue
    };
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.post(`${API_ADDRESS}/feedback/submit`, data, config)
      .then(() => {
        mixpanel.track('Feedback Submitted', data);
        setStep(STEPS.ISSUE_FINAL);
        dispatch(setLoading({key: FEEDBACK_LOADING_KEY, value: false}));
      });
  }


  const getQuestions = () => {
    const questions = [];

    switch(step) {
      case STEPS.HAD_CALL:
        return (
          <Grid key="had_call" className={clsx([styles.centerText])}>
            <Typography variant="subtitle1" className={styles.boldText}>Did {user.FirstName} show up for the call?</Typography>
            <ToggleButtonGroup value={hadCall} onChange={handleHadCallChange} className={classes.bottomMargin}>
              <ToggleButton value={CONST_YES}>
                {CONST_YES}
              </ToggleButton>
              <ToggleButton value={CONST_NO}>
                {CONST_NO}
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="caption" className={styles.centerElements}>Please allow {user.FirstName} some time to join before clicking No.</Typography>
          </Grid>
        );

      case STEPS.STAY_CONNECTED:
        return (
          <Grid key="stay_connected" flex-direction="column" className={styles.centerText}>
            <Typography variant="subtitle1" className={styles.boldText}>Add {user.FirstName} to your network?</Typography>
            <ToggleButtonGroup value={stayConnected} onChange={handleStayConnectedChange} className={classes.bottomMargin}>
              <ToggleButton value={CONST_YES}>
                {CONST_YES}
              </ToggleButton>
              <ToggleButton value={CONST_NO}>
                {CONST_NO}
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="caption" className={styles.centerElements}>
              If {user.FirstName} is in your network, you will be able to schedule calls directly on their calendar when you want to speak with them again.
              They will only be added to your network if they also say yes.
            </Typography>
          </Grid>
        );

      case STEPS.ISSUE:
        return (
          <Grid key="issue" className={classes.section}>
            <Box className={clsx([styles.centerText, classes.bottomMargin])}>
              <Typography variant="subtitle1" className={styles.boldText}>Was {user.FirstName} a relevant match for you?</Typography>
              <ToggleButtonGroup value={relevant} onChange={handleRelevant} className={classes.bottomMargin}>
                <ToggleButton value={CONST_YES}>
                  {CONST_YES}
                </ToggleButton>
                <ToggleButton value={CONST_NO}>
                  {CONST_NO}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box className={clsx([styles.centerText, classes.bottomMargin])}>
              <Typography variant="subtitle1" className={styles.boldText}>If there was another issue or something that violates our Community Guidelines.</Typography>
              <TextField
                id={ISSUE}
                name={ISSUE}
                label="Tell us here"
                variant="outlined"
                fullWidth
                required
                value={issue}
                onChange={handleTextField}
                margin="dense"
                multiline
                rows={4}
                rowsMax={7}
                style={{backgroundColor: 'white'}}
              />

            </Box>
            <Box className={styles.centerElements}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        );
    }
  }

  const getBody = () => {
    switch(step) {
      case STEPS.ISSUE_FINAL:
        return getIssueFinal();
      case STEPS.THANK_YOU:
        return getThanks();
      case STEPS.HAD_CALL:
      case STEPS.STAY_CONNECTED:
      case STEPS.ISSUE:
        return getQuestions();
    }

  }

  return (
    <Paper variant="outlined" className={clsx([styles.innerPadding, classes.container])}>
      <ElementOrLoader loading={loading[FEEDBACK_LOADING_KEY]}>
        <Grid container direction="column">
          {getBody()}
        </Grid>
      </ElementOrLoader>
    </Paper>
  );
}

export default FeedbackSurvey;
